import React, { useState } from 'react';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import Tooltip from 'uibuilder/Tooltip';
import Typography from 'uibuilder/Typography';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import Box from 'uibuilder/Box';
import { IconButton } from '@mui/material';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import useEmployeesDashboardService from 'erp/employee/dashboard/useEmployeesDashboardService';
import { DashboardEmployee } from 'erp/employee/dashboard/types';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const FollowUpSummaryButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { createFollowUp, getFollowUpValidation } = useEmployeesDashboardService();
  const { data, setData } = useShowContext();

  const { formatDate } = useDateService();

  const employeeDashboardData = (data?.getData() || {}) as DashboardEmployee;
  const { retentionRiskId: riskId = '', retentionRiskFollowUps: followUps = [] } = employeeDashboardData;

  const toggleModal = () => {
    setModalOpen(prev => !prev);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'fit-content' }}>
        <Box sx={{ alignSelf: 'start' }}>
          <IconButton onClick={toggleModal} sx={{ color: 'var(--mui-palette-primary-main)', mr: '6px', p: 0 }}>
            <NotificationAddOutlinedIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {followUps.map(followUp => (
            <Tooltip title={followUp.description} placement="top">
              <div key={followUp.id}>
                <Typography color="var(--mui-palette-primary-main)">
                  {formatDate(followUp.followUpDate, DATE_FORMAT.NATURAL)}
                </Typography>
              </div>
            </Tooltip>
          ))}
        </Box>
      </Box>

      <ModalWindow isOpen={modalOpen} onToggle={() => toggleModal()}>
        <ModalForm
          getValidationSchemaFunc={getFollowUpValidation}
          closeModal={() => toggleModal()}
          modalOpen={modalOpen}
          submitFormFunc={requestData => createFollowUp(riskId, requestData)}
          afterSubmit={{
            execute: response => {
              setData!({
                ...employeeDashboardData,
                retentionRiskFollowUps: [response, ...followUps].sort(
                  (a, b) => new Date(a.followUpDate).getTime() - new Date(b.followUpDate).getTime(),
                ),
              });
            },
          }}
        >
          <DateInput label="" placeholder="Reminder date" source="followUpDate" />
          <TextArea label="" placeholder="Text" source="description" />
        </ModalForm>
      </ModalWindow>
    </>
  );
};

export default FollowUpSummaryButton;
