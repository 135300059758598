import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import { IconButton } from 'uibuilder/button';
import { EMPLOYEE_DASHBOARD_COLORS } from '../../constants';
import Badge from 'shared/uibuilder/badge/Badge';
import { useDurationUtils } from './durationUtils';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useEmployeeContractsService from '../../../contracts/shared/employeeContractsService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { Contract } from '../types';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface CustomStepIconProps {
  active?: boolean;
  style?: CSSProperties;
  sx?: SxProps<Theme>;
}

const CustomStepIcon = ({ active, style }: CustomStepIconProps) => {
  return (
    <div
      style={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: style?.color || (active ? EMPLOYEE_DASHBOARD_COLORS.SAFE : 'var(--mui-palette-neutral-40)'),
        display: 'flex',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    />
  );
};

const ContractsTimeline = () => {
  const { data } = useShowContext();
  const { employeeAlias } = data.getData();
  const { addMessage } = useMessageService();
  const { getAll } = useEmployeeContractsService();
  const [contracts, setContracts] = useState<Contract[]>();
  const { getMomentDateObj, formatDate } = useDateService();
  const [expanded, setExpanded] = useState(false);
  const { calculateTotalDuration, formatDuration } = useDurationUtils();

  useEffect(() => {
    if (!contracts) {
      getAll(employeeAlias)
        .then(result => setContracts(result.result))
        .catch(() => {
          addMessage(new ErrorMessage('Failed to load contracts'));
        });
    }
  }, [addMessage, contracts, employeeAlias, getAll]);

  const MAX_VISIBLE_NODES = 3;

  if (!contracts || contracts.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="120px">
        <Typography variant="body2" color="textSecondary">
          No contracts available
        </Typography>
      </Box>
    );
  }

  const sortedContracts = [...contracts].sort((a, b) => {
    const dateA = getMomentDateObj(a.startDate);
    const dateB = getMomentDateObj(b.startDate);
    return dateA.valueOf() - dateB.valueOf();
  });

  const shouldCollapse = sortedContracts.length > MAX_VISIBLE_NODES;

  const formatDateToMonthYear = (dateString: string) => {
    const date = getMomentDateObj(dateString);
    return formatDate(date, DATE_FORMAT.MONTH);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getVisibleContracts = () => {
    if (!shouldCollapse || expanded) {
      return sortedContracts;
    }

    const firstHalf = Math.floor(MAX_VISIBLE_NODES / 2);
    const secondHalf = MAX_VISIBLE_NODES - firstHalf - 1;

    return [
      ...sortedContracts.slice(0, firstHalf),
      { isExpandButton: true },
      ...sortedContracts.slice(sortedContracts.length - secondHalf),
    ];
  };

  const totalDuration = calculateTotalDuration(sortedContracts);
  const formattedTotalDuration = formatDuration(totalDuration);

  return (
    <Box>
      <Badge
        sx={{
          backgroundColor: 'var(--mui-palette-neutral-40)',
          color: 'white',
          mb: 2,
          ml: 2,
        }}
      >
        {formattedTotalDuration}
      </Badge>
      <Stepper
        alternativeLabel
        sx={{
          overflowX: 'auto',
          height: '120px',
          '& .expand-button .MuiStepLabel-root': {
            paddingTop: '10px',
          },
          '& .MuiStepConnector-root': {
            left: 'calc(-50%)',
            right: 'calc(50%)',
            marginTop: '11px',
          },
          '& .MuiStepLabel-root': { paddingTop: '20px' },
        }}
      >
        {getVisibleContracts().map((contract, index, array) => {
          if ('isExpandButton' in contract) {
            return (
              <Step
                key="expand-button"
                active={false}
                sx={{ minWidth: '100px', width: '100px', maxWidth: '100px', p: 0 }}
                className="expand-button"
              >
                <StepLabel
                  slots={{
                    stepIcon: () => (
                      <IconButton
                        onClick={toggleExpanded}
                        style={{
                          padding: 0,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Step>
            );
          }

          const isLastStep = index === array.length - 1;

          const lastNodeColor = EMPLOYEE_DASHBOARD_COLORS.SAFE;
          const nodeColor = isLastStep ? lastNodeColor : 'var(--mui-palette-neutral-40)';

          const startDateFormatted = formatDateToMonthYear(contract.startDate);
          const endDateFormatted = contract.indefinite ? '∞' : formatDateToMonthYear(contract.endDate);
          const dateRange = `${startDateFormatted} - ${endDateFormatted}`;

          return (
            <Step
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              active={isLastStep}
              sx={{
                minWidth: '100px',
                width: '100px',
                maxWidth: '100px',
                p: 0,
                '.Mui-disabled': { cursor: 'default' },
              }}
            >
              <Tooltip
                title={
                  <>
                    <Typography sx={{ fontSize: '10px' }}>Start date: {contract.startDate}</Typography>
                    <Typography sx={{ fontSize: '10px' }}>
                      End date: {contract.indefinite ? '∞' : contract.endDate}
                    </Typography>
                    {contract.employeePositionName && (
                      <Typography sx={{ fontSize: '10px' }}> Position: {contract.employeePositionName}</Typography>
                    )}
                  </>
                }
                arrow
                placement="top"
              >
                <StepLabel
                  slots={{
                    stepIcon: props => (
                      <CustomStepIcon
                        {...props}
                        sx={{
                          color: nodeColor,
                          paddingTop: '20px',
                        }}
                      />
                    ),
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      style={{
                        paddingBottom: '20px',
                        marginTop: '-40px',
                        textAlign: 'center',
                        fontSize: '10px',
                      }}
                    >
                      {isLastStep ? dateRange : startDateFormatted}
                    </Typography>
                    <Badge sx={{ backgroundColor: nodeColor, color: 'white' }}>{contract.type}</Badge>
                    <EmployeeOfficeField value={contract.officeId} />
                  </div>
                </StepLabel>
              </Tooltip>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default ContractsTimeline;
