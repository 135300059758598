import List from 'shared/uibuilder/list/List';
import { BadgeField, DateField, ListColumnTotalField, PercentField, TextField } from 'shared/uibuilder/field';
import BenchBadge from 'erp/employee/assignments/shared/field/BenchBadge';
import AssignmentTargetLinkField from 'erp/assignment/target/shared/field/AssignmentTargetLinkField';
import { EmployeeLinkField } from '../../../index';
import PositionInAssignmentField from 'erp/assignment/shared/field/PositionInAssignmentField';
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';
import { Link } from 'react-router-dom';
import { useEmployeeUrl } from '../../../EmployeesRouter';
import CollapsibleDataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout/CollapsibleDataGridLayout';
import AssignmentDurationBadge from './AssignmentDurationBadge';
import { ResourceData } from 'shared/crud/baseCrudService';
import useAssignmentService, { ACTIVE_STATUS } from 'erp/assignment/shared/assignmentService';

export const getPercentLabel = (totalPercent: number) => (
  <Typography sx={{ fontWeight: 'bold', color: 'var(--mui-palette-badge-green)', fontSize: '12px' }}>
    {totalPercent}%
  </Typography>
);

const SummaryAssignments = () => {
  const { search } = useAssignmentService();
  const { data } = useShowContext();
  const { employeeAlias } = data.getData();
  const { getEmployeeAssignmentsUrl } = useEmployeeUrl();

  const getAssignmentsDataMethod = async (params: ResourceData) => {
    return search({
      ...params,
      filter: {
        'specification:assignmentsByEmployeeAlias': { eq: employeeAlias },
        status: { in: [ACTIVE_STATUS] },
        ...params.filter,
      },
    });
  };

  return (
    <List getDataMethod={getAssignmentsDataMethod}>
      <Box
        sx={{
          '& .list-general tbody td': { border: 0, fontSize: '12px', p: 1 },
          '& .list-general th': { width: 'auto', p: 1 },
          pb: 2,
        }}
      >
        <CollapsibleDataGridLayout
          buttons={[]}
          isSmallTable
          nonExpandedHeader={
            <Link
              style={{ fontSize: '12px', fontWeight: 'bold', color: 'var(--mui-palette-primary-main)' }}
              target="_blank"
              to={getEmployeeAssignmentsUrl(employeeAlias)}
            >
              Assignments
            </Link>
          }
        >
          <BadgeField
            badges={[<BenchBadge isVisible={assignment => assignment.bench} />]}
            label={
              <Link
                style={{ fontSize: '12px', fontWeight: 'bold', color: 'var(--mui-palette-primary-main)' }}
                target="_blank"
                to={getEmployeeAssignmentsUrl(employeeAlias)}
              >
                Assignments
              </Link>
            }
            source="target.id"
            nameSource="target.displayName"
            typeSource="target.type"
            style={{ display: 'inline-flex' }}
          >
            <AssignmentTargetLinkField source="target.id" nameSource="target.displayName" typeSource="target.type" />
          </BadgeField>
          <BadgeField badges={[<AssignmentDurationBadge endDateSource="to" />]} />
          <PercentField
            source="minPercent"
            label={<ListColumnTotalField source="minPercent" value={getPercentLabel} />}
          />
          <TextField label={<Typography sx={{ color: 'var(--mui-palette-badge-green)' }}>-</Typography>} value="-" />
          <PercentField
            source="maxPercent"
            label={<ListColumnTotalField source="maxPercent" value={getPercentLabel} />}
          />
          <PositionInAssignmentField source="assignmentType" label="" />
          <EmployeeLinkField source="ownerId" nameSource="ownerName" label="" />
          <DateField source="from" label=" " />
          <Typography>-</Typography>
          <DateField source="to" label=" " />
        </CollapsibleDataGridLayout>
      </Box>
    </List>
  );
};

export default SummaryAssignments;
