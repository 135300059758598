// eslint-disable-next-line no-restricted-syntax
import mixpanel from 'mixpanel-browser';

export function initMixpanel(mixpanelTokenVal) {
  if (mixpanelTokenVal) {
    mixpanel.init(mixpanelTokenVal, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      autocapture: true,
      ip: true,
      track_marketing: true,
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn('Mixpanel token is undefined');
  }
}

export default {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  reset: () => {
    mixpanel.reset();
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
  },
};
