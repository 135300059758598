import React, { useMemo } from 'react';
import useAsyncValue from 'shared/asyncHelper';
import useArtifactService from 'artifact/artifactService';
import { API_FILE_TYPE, CROP_SIZES } from 'artifact/const';
import DefaultUserAvatar from 'uibuilder/AppIcon/Icons/DefaultUserAvatar';

const EmployeeAvatar = ({ avatarId }: any) => {
  const { getArtifactDataById } = useArtifactService();
  const artifact = useAsyncValue(async () => (avatarId ? getArtifactDataById(avatarId) : null), [avatarId]);

  return useMemo(() => {
    if (artifact) {
      const image = Array.isArray(artifact) ? artifact[0] : artifact;

      if (image && image.type === API_FILE_TYPE.image.alias) {
        let thumbnail = image.thumbnails && image.thumbnails[CROP_SIZES.SIZE_100];
        if (typeof thumbnail === 'undefined') {
          thumbnail = image.filePreview;
        }
        const { id, title } = image;

        return <img src={thumbnail} alt={title} title={title} id={id} />;
      }
    }

    return <DefaultUserAvatar sx={{ width: '100px', height: '100px' }} />;
  }, [artifact]);
};

export default React.memo(EmployeeAvatar);
