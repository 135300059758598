import moment from 'moment';
import { useDateService } from 'shared/uibuilder/dateService';
import { Contract } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const useDurationUtils = () => {
  const { getToday, getMomentDateObj, getStringDurationBetweenTwoDates } = useDateService();

  const calculateContractDuration = (startDate: string): string => {
    const start = getMomentDateObj(startDate);
    const now = getToday();

    if (start.isAfter(now)) {
      return '0d';
    }

    const durationString = getStringDurationBetweenTwoDates(startDate, now.format('YYYY-MM-DD'));

    return formatDurationString(durationString || '0 days');
  };

  const formatDurationString = (durationString: string): string => {
    if (!durationString) return '0d';

    let cleanDuration = durationString.replace('- ', '');

    cleanDuration = cleanDuration
      .replace('years', 'y')
      .replace('year', 'y')
      .replace('months', 'm')
      .replace('month', 'm')
      .replace('days', 'd')
      .replace('day', 'd');

    cleanDuration = cleanDuration.replace(/,\s*/g, ' ');

    return cleanDuration;
  };

  const formatDuration = (duration: moment.Duration): string => {
    let result = '';

    if (duration.years() > 0) {
      result += `${duration.years()}y `;
    }

    if (duration.months() > 0 || duration.years() > 0) {
      result += `${duration.months()}m `;
    }

    result += `${duration.days()}d`;

    return result;
  };

  const calculateTotalDuration = (contracts: Contract[]): moment.Duration => {
    const resultDuration = moment.duration();

    if (contracts.length === 0) {
      return resultDuration;
    }

    const now = getToday();

    const intervals = contracts
      .map((contract: Contract) => [getMomentDateObj(contract.startDate), getMomentDateObj(contract.endDate)])
      .sort((a, b) => a[0].diff(b[0]))
      .filter(a => !a[0].isAfter(now));

    let lastDay: moment.Moment;
    intervals.forEach(([startDate, endDate]) => {
      const realStartDate = lastDay && lastDay.isAfter(startDate) ? lastDay : startDate;
      let realEndDate = lastDay && lastDay.isAfter(endDate) ? undefined : endDate;

      if (realEndDate) {
        realEndDate = now.isBefore(endDate) ? now : endDate;

        const calculatedDiffDays = moment.duration(realEndDate.diff(realStartDate));
        resultDuration.add(calculatedDiffDays);
        lastDay = realEndDate;
      }
    });

    return resultDuration;
  };

  return {
    calculateContractDuration,
    formatDuration,
    formatDurationString,
    calculateTotalDuration,
  };
};
