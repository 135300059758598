import React, { useState } from 'react';
import Alert from 'uibuilder/Alert';
import Table from '@mui/material/Table';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import TableHead, { TableHeadProps } from './TableHead';
import TableBody, { TableBodyProps } from './TableBody';
import { TableBodyWithGroupingProps } from './TableBodyWithGrouping';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import ListFieldLayout from 'uibuilder/layout/field/ListFieldLayout';
import ListLoadingDecorator from 'shared/uibuilder/list/layout/ListLoadingDecorator';
import './index.scss';
import TextField from 'shared/uibuilder/field/TextField';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface DataGridLayoutProps extends TableHeadProps, TableBodyProps {
  isSmallTable?: boolean;
  tableWrapperClassName?: string;
  isSmallLoader?: boolean;
  rowKeySource?: string;
  tableHeadComponent?: React.FC<TableHeadProps>;
  nonExpandedHeader?: string | JSX.Element | (() => string | JSX.Element) | null | undefined;
  tableBodyComponent?: React.FC<TableBodyProps | TableBodyWithGroupingProps>;
  emptyListMessageComponent?: React.ReactNode;
  customEmptyMessage?: string;
}

const CollapsibleDataGridLayout = ({
  children,
  buttons,
  rowProps = {},
  isSmallTable = false,
  isSmallLoader = false,
  tableWrapperClassName = '',
  rowKeySource,
  tableHeadComponent: TableHeadComponent = TableHead,
  nonExpandedHeader,
  tableBodyComponent: TableBodyComponent = TableBody,
  tableRowComponent,
  emptyListMessageComponent = '',
  customEmptyMessage = '',
}: DataGridLayoutProps) => {
  const { data: { items = [] } = {}, loading } = useListContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const emptyListComponent = () => {
    if (emptyListMessageComponent) {
      return emptyListMessageComponent;
    }

    return (
      <Alert color="info" className="mt-3">
        {customEmptyMessage || 'No records found'}
      </Alert>
    );
  };

  const expandField = (
    <TextField
      width={20}
      value=" "
      label={
        <IconButton
          size="small"
          onClick={() => setIsExpanded(prevState => !prevState)}
          sx={{
            padding: 0,
            transition: 'transform 0.3s ease',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      }
    />
  );

  return (
    <ListLoadingDecorator items={items} loading={loading} isSmallLoader={isSmallLoader}>
      <UiThemeOverrider
        overriddenLayouts={{
          BaseFieldLayout: ListFieldLayout,
        }}
      >
        <div className={isSmallTable ? 'table-responsive list-general list-general--sm' : 'table-responsive'}>
          <div className={`table-wrapper ${tableWrapperClassName}`}>
            <Table
              size={isSmallTable ? 'small' : 'medium'}
              className={`${isSmallTable ? 'table table-hover' : 'table-hover list-general'}`}
            >
              <TableHeadComponent isShowHeader buttons={buttons}>
                {expandField}
                {isExpanded && items.length ? children : <TextField value=" " label={nonExpandedHeader} />}
              </TableHeadComponent>
              {isExpanded && items.length > 0 && (
                <TableBodyComponent
                  rowProps={rowProps}
                  buttons={buttons}
                  rowKeySource={rowKeySource}
                  tableRowComponent={tableRowComponent}
                >
                  {expandField}
                  {children}
                </TableBodyComponent>
              )}
            </Table>
            {isExpanded && !items.length && emptyListComponent()}
          </div>
        </div>
      </UiThemeOverrider>
    </ListLoadingDecorator>
  );
};

export default CollapsibleDataGridLayout;
