import React from 'react';
import Healthcheck from './healthcheck/Healthcheck';
import EmployeeMainInfo from './EmployeeMainInfo';
import ChangeZoneButton from './ChangeZoneIssues/IssuesModalButton';
import IssuesEmployeeSummaryContainer from './ChangeZoneIssues/IssuesEmployeeSummaryContainer';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { StyledGrid } from '../List/EmployeeDashboardItem/EmployeeDashboardItem';
import EditIssueButton from './ChangeZoneIssues/EditIssueButton';
import Box from 'uibuilder/Box';
import { Grid2 as Grid } from '@mui/material';
import AssingEmployee from '../shared/fields/AssingEmployee/AssingEmployee';
import ContractsTimeline from './contracts/ContractsTimeline';
import SummaryAssignments from './assignments/SummaryAssignments';
import LinkIconButtonsEmployeeDashboard from '../shared/buttons/LinkIconButtonsEmployeeDashboard';

const EmployeeSummary = () => {
  const { data } = useShowContext();

  return (
    <>
      <Grid container columns={14} spacing={1} justifyContent="space-between" alignItems="start" mb={4}>
        <StyledGrid size={{ xs: 4 }}>
          <EmployeeMainInfo />
        </StyledGrid>
        <StyledGrid size={{ xs: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mb: 1, justifyContent: 'space-between' }}>
            <IssuesEmployeeSummaryContainer issues={data.retentionRiskIssues} />
            <EditIssueButton />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <AssingEmployee />
            <LinkIconButtonsEmployeeDashboard />
          </Box>
        </StyledGrid>
        <StyledGrid size={{ xs: 4 }}>
          <ContractsTimeline />
        </StyledGrid>
        <StyledGrid size={{ xs: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
            <Healthcheck />
            <ChangeZoneButton />
          </Box>
        </StyledGrid>
      </Grid>

      <SummaryAssignments />
    </>
  );
};
export default EmployeeSummary;
