import React, { useMemo } from 'react';
import { useDateService } from 'shared/uibuilder/dateService';
import Badge from 'shared/uibuilder/badge/Badge';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface FieldBadgeProps extends FieldProps {
  endDateSource: string;
}

const AssignmentDurationBadge = ({ endDateSource }: FieldBadgeProps) => {
  const { getValue: getEndDate } = useFieldHelper({ source: endDateSource });
  const { getDaysBetween, getMonthsBetween, getCurrentDateTimeInUtc } = useDateService();
  const now = getCurrentDateTimeInUtc();

  const badgeData = useMemo(() => {
    if (!getEndDate()) {
      return null;
    }

    const daysRemaining = getDaysBetween(getEndDate(), now);
    const isWarning = daysRemaining < 14;
    const monthsRemaining = getMonthsBetween(getEndDate(), now);

    let formattedTime;
    if (monthsRemaining! >= 12) {
      const years = Math.floor(monthsRemaining! / 12);
      formattedTime = `${years}y`;
    } else if (monthsRemaining! >= 1) {
      formattedTime = `${Math.floor(monthsRemaining!)}m`;
    } else {
      formattedTime = `${daysRemaining}d`;
    }

    return {
      formattedTime,
      isWarning,
    };
  }, [getEndDate, now, getDaysBetween, getMonthsBetween]);

  if (!getEndDate() || !badgeData) {
    return null;
  }

  return (
    <Badge
      sx={{
        backgroundColor: badgeData.isWarning ? 'var(--mui-palette-background-error)' : 'var(--mui-palette-neutral-60)',
        color: badgeData.isWarning ? 'var(--mui-palette-neutral-40)' : 'white',
        fontSize: '12px',
      }}
    >
      {badgeData.formattedTime}
    </Badge>
  );
};

export default AssignmentDurationBadge;
