import React, { useState, useEffect } from 'react';
import { Collapse, Grid2 as Grid, styled } from '@mui/material';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import Box from 'uibuilder/Box';
import { IconButton } from 'uibuilder/button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FollowUpEmployeeDashboardField from '../../shared/fields/FollowUpEmployeeDashboardField';
import IssuesEmployeeDashboardFiled from '../../shared/fields/IssuesEmployeeDashboardFiled';
import AssingEmployee from '../../shared/fields/AssingEmployee/AssingEmployee';
import LinkIconButtonsEmployeeDashboard from '../../shared/buttons/LinkIconButtonsEmployeeDashboard';
import HealthcheckStatus from '../../summary/healthcheck/HealthcheckStatus';
import EmployeeSummary from '../../summary/EmployeeSummary';
import { BadgeField, TextField } from 'shared/uibuilder/field';
import WorkingStatusChip from '../../summary/WorkingStatusChip';

export const StyledGrid = styled(Grid)(() => ({
  fontSize: '12px',
  '.MuiTypography-root': {
    fontSize: '12px',
  },
  '.field__wrapper > .MuiTypography-root': {
    fontSize: '12px',
  },
}));

const EmployeeDashboardItem = () => {
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [showGrid, setShowGrid] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isSummaryExpanded) {
      // Hide grid immediately when expanding
      setShowGrid(false);
    } else {
      // Show grid after animation completes when collapsing
      timer = setTimeout(() => {
        setShowGrid(true);
      }, 300);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isSummaryExpanded]);

  return (
    <Box
      sx={{
        border: '1px solid var(--mui-palette-TableCell-border)',
        borderRadius: '3px',
        padding: '2px 1px',
        mb: '5px',
        display: 'flex',
        minHeight: '40px',
        justifyContent: 'space-between',
        alignItems: isSummaryExpanded ? 'flex-start' : 'center',
      }}
    >
      <IconButton
        size="small"
        onClick={() => setIsSummaryExpanded(prevState => !prevState)}
        sx={{
          mr: '4px',
          padding: 0,
          width: '23px',
          transition: 'transform 0.3s ease',
          transform: isSummaryExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <KeyboardArrowDownIcon sx={{ width: '21px' }} />
      </IconButton>

      {showGrid && !isSummaryExpanded && (
        <Grid container width="100%" columns={14} spacing={1} justifyContent="space-between" alignItems="center">
          <StyledGrid size={{ xs: 2 }}>
            <BadgeField style={{ display: 'flex', alignItems: 'center' }} badges={[<WorkingStatusChip />]}>
              <EmployeeLinkField nameSource="employeeName" source="employeeAlias" label="" />
            </BadgeField>
          </StyledGrid>
          <StyledGrid size={{ xs: 2 }}>
            <TextField source="employeeSpecialization" label="" />
          </StyledGrid>
          <StyledGrid size={{ xs: 2 }}>
            <FollowUpEmployeeDashboardField />
          </StyledGrid>

          <StyledGrid size={{ xs: 3 }}>
            <IssuesEmployeeDashboardFiled />
          </StyledGrid>

          <StyledGrid size={{ xs: 2 }}>
            <AssingEmployee />
          </StyledGrid>

          <StyledGrid size={{ xs: 1 }}>
            <LinkIconButtonsEmployeeDashboard />
          </StyledGrid>

          <StyledGrid size={{ xs: 2 }}>
            <HealthcheckStatus iconSx={{ fontSize: 12 }} />
          </StyledGrid>
        </Grid>
      )}

      <Collapse sx={{ width: '100%' }} in={isSummaryExpanded} timeout={300} unmountOnExit>
        <Box p="8px">
          <EmployeeSummary />
        </Box>
      </Collapse>
    </Box>
  );
};

export default EmployeeDashboardItem;
