import React from 'react';
import Alert from 'uibuilder/Alert';
import useTimelineRegistryHelper from 'shared/uibuilder/timeline/timelineRegistryHelper';
import TimelineRecord from 'shared/uibuilder/timeline/TimelineRecordWrapper';
import Loading from 'shared/uibuilder/Loading';
import TimelinePagingHandler from 'shared/uibuilder/timeline/paging/TimelinePagingHandler';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineItem, TimelineItemId } from 'shared/uibuilder/timeline/timelineHelper';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Data from 'shared/uibuilder/Data';

import { Step, StepLabel, Stepper } from '@mui/material';
import StepContent from '@mui/material/StepContent';
import Box from '@mui/material/Box';
import styles from '../CandidateTimelineRecord/CandidateTimelineRecord.module.scss';
import Typography from 'uibuilder/Typography';
import { Assignment, AttachEmail, EditNote } from '@mui/icons-material';
import { CANDIDATE_TIMELINE_MESSAGE_CONTEXT } from '../CandidateTimelinePageLayout';

const DEFAULT_FUNCTION = () => {};

interface TimelineLayoutProps {
  noEntriesMessage: string;
}

const formConfig = {
  CreateCandidateNoteForm: {
    icon: <EditNote />,
    text: 'New note',
  },
  CreateEmailForm: {
    icon: <AttachEmail />,
    text: 'New communication',
  },
  CreateCandidateOfferForm: {
    icon: <Assignment />,
    text: 'New offer',
  },
  default: {
    icon: <EditNote />,
    text: 'New entry',
  },
};

const getFormConfig = (form: any) => {
  if (!form) {
    return formConfig.default;
  }
  const formTypeName = form.type.name || '';

  return formConfig[formTypeName as keyof typeof formConfig] || formConfig.default;
};

const CandidateTimelineLayout = ({ noEntriesMessage = 'No entries in this timeline yet.' }: TimelineLayoutProps) => {
  const {
    data: { items = [] } = {},
    registries,
    currentEditingId,
    setCurrentEditingId = DEFAULT_FUNCTION,
    updateInList = DEFAULT_FUNCTION,
    wrapItem = () => ({}),
    loading,
    addForm,
    isHighlighted = () => false,
  }: TimelineContextData = useListContext();
  const { getRegistry } = useTimelineRegistryHelper(registries || []);
  const { data: parentContext } = useShowContext();
  const { icon: formIcon, text: formText } = getFormConfig(addForm);

  const clearEditing = () => setCurrentEditingId(null);

  const renderUpdateForm = (
    UpdateFormTemplate: React.ReactElement,
    timelineItemEntity: TimelineItem,
    source: string,
    hasFullData: boolean,
    additionalAfterUpdateSubmit?: (arg: TimelineItem, parentContext?: Data) => void,
  ) => (
    // @ts-ignore
    <UpdateFormTemplate
      messageContext={CANDIDATE_TIMELINE_MESSAGE_CONTEXT}
      entityId={timelineItemEntity?.id}
      afterSubmit={{
        execute: (id: TimelineItemId, data: TimelineItem) => {
          if (data) {
            updateInList(
              timelineItemEntity?.id || null,
              hasFullData
                ? data
                : wrapItem(
                    {
                      ...data,
                      timelineId: timelineItemEntity?.timelineId,
                    },
                    source,
                  ),
            );
            clearEditing();
          }
          if (additionalAfterUpdateSubmit) {
            additionalAfterUpdateSubmit(data, parentContext);
          }
        },
      }}
      key={`form-${timelineItemEntity?.id}`}
      getDataFunc={() => timelineItemEntity}
      onCancel={clearEditing}
    />
  );

  const renderLayout = (
    UpdateForm: React.ReactElement,
    RecordLayout: React.ReactElement,
    timelineItemEntity: TimelineItem,
    source: string,
    hasFullData: boolean,
    additionalAfterUpdateSubmit?: (arg: TimelineItem, parentContext?: Data) => void,
  ) => (
    // @ts-ignore
    <RecordLayout
      updateComponent={renderUpdateForm(
        UpdateForm,
        timelineItemEntity,
        source,
        hasFullData,
        additionalAfterUpdateSubmit,
      )}
      isHighlighted={isHighlighted(timelineItemEntity?.timelineId || timelineItemEntity?.id)}
      isEditing={currentEditingId === timelineItemEntity?.id}
      onEdit={() => setCurrentEditingId(timelineItemEntity?.id || null)}
      key={`record-${timelineItemEntity?.id}`}
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {!items.length && !addForm && (
        <Alert severity="info" className="mt-3">
          {noEntriesMessage}
        </Alert>
      )}
      <div>
        <TimelinePagingHandler>
          <Stepper orientation="vertical" sx={{ '& .MuiStepConnector-line': { minHeight: 0 } }}>
            {addForm && (
              <Step key={`entry${items.length}`}>
                <StepLabel sx={{ padding: 0 }} icon={<Box className={styles.icon_container}>{formIcon}</Box>}>
                  <Typography
                    sx={{
                      backgroundColor: 'lavender',
                      pr: 1,
                      fontWeight: 500,
                      textAlign: 'right',
                      color: 'var(--mui-palette-primary-40)',
                    }}
                  >
                    {formText}
                  </Typography>
                </StepLabel>
                <StepContent sx={{ pt: '20px', pb: '30px' }}>{addForm}</StepContent>
              </Step>
            )}

            {items.map((item: any) => {
              if (!item) return null;

              const {
                UpdateForm = <div />,
                source = '',
                RecordLayout = <div />,
                // @ts-ignore
                hasFullData = false,
                additionalAfterUpdateSubmit,
              } = getRegistry(item) || {};
              // @ts-ignore

              let timelineItemEntity;
              if (hasFullData) {
                timelineItemEntity = item;
              } else if (source && item[source]) {
                timelineItemEntity = item[source];
              } else {
                timelineItemEntity = item;
              }

              if (!timelineItemEntity || !timelineItemEntity.id) {
                return null;
              }

              return (
                <React.Fragment key={timelineItemEntity.id}>
                  <TimelineRecord entity={timelineItemEntity || {}} key={`wrapper-${timelineItemEntity.id}`}>
                    {renderLayout(
                      UpdateForm,
                      RecordLayout,
                      timelineItemEntity,
                      source,
                      hasFullData,
                      additionalAfterUpdateSubmit,
                    )}
                  </TimelineRecord>
                </React.Fragment>
              );
            })}
          </Stepper>
        </TimelinePagingHandler>
      </div>
    </>
  );
};

export default CandidateTimelineLayout;
